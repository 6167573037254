<template>
	<div class="role_manage">
		<el-card>
			<el-row class="left">
				<el-col :span="24">
					<el-form :inline="true" class="demo-form-inline">
						<el-form-item label="角色名称:">
							<el-input v-model="role_name" placeholder="请输入角色名称" clearable></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
							<el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<el-row class="left mb30">
				<el-col :span="24">
					<el-button type="primary" size="mini" icon="el-icon-plus" @click="addRole">添加</el-button>
					<el-button type="danger" size="mini" icon="el-icon-delete" @click="removeAll">删除</el-button>
				</el-col>
			</el-row>
			<el-table border :data="roleList" @select="select" @select-all="selectAll" style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column fixed type="selection"></el-table-column>
				<el-table-column label="ID" prop="role_id" width="80"></el-table-column>
				<el-table-column label="角色名称" prop="role_name" width="150"></el-table-column>
				<el-table-column label="角色状态">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.is_del" active-value="0" inactive-value="1" @change="setRoleStatus(scope.row)"></el-switch>
					</template>
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" label="备注" prop="role_desc" width="200"></el-table-column>
				<el-table-column label="创建时间" prop="add_time" width="150"></el-table-column>
				<el-table-column label="更新时间" prop="update_time" width="150"></el-table-column>
				<el-table-column label="操作" fixed="right" width="250">
					<template slot-scope="scope">
						<div>
							<el-button type="primary" size="mini" @click="updateRole(scope.row)">修改</el-button>
							<el-button type="primary" size="mini" @click="fenpei(scope.row)">分配权限</el-button>
							<el-button type="danger" size="mini" @click="remove(scope.row)">删除</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
		</el-card>
		<!-- 添加/编辑 -->
		<el-dialog :title="title" width="450px" :visible.sync="addRoleVisible">
			<el-form ref="addRoleRef" :model="addRoleForm" :rules="addRoleRules" label-width="100px" class="left">
				<el-form-item label="角色名称:" prop="role_name">
					<el-input v-model="addRoleForm.role_name" placeholder="请输入角色名称"></el-input>
				</el-form-item>
				<el-form-item label="备注:">
					<el-input type="textarea" :rows="4" v-model="addRoleForm.role_desc" placeholder="请输入备注"></el-input>
				</el-form-item>
			</el-form>
			<div>
				<el-button type="default" @click="addClose">取消</el-button>
				<el-button type="primary" @click="addSub">保存</el-button>
			</div>
		</el-dialog>
		<!-- 分配权限 -->
		<el-dialog title="分配权限" :visible.sync="fenpeiVisible" width="450px">
			<div class="trees">
				<!-- default-checked-keys默认勾选的节点的 key 的数组 -->
				<!-- node-key树节点唯一标识 -->
				<el-tree :data="menuList" :props="treeProps" show-checkbox default-expand-all :default-checked-keys="defKeys" node-key="menu_id" ref="treeRef">
				</el-tree>
			</div>
			<div>
				<el-button type="default" @click="fenpeiClose">取消</el-button>
				<el-button type="primary" @click="fenpeiSub">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	let that;
	let timer = null;
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		components: {
			Pagination
		},
		data() {
			return {
				queryInfo: {
					pagenum: 1,
					pagesize: 10,
				},
				total: 0,
				role_id: 0,
				role_name: '',
				roleList: [],
				chooseList: [],
				title: '添加角色',
				addRoleVisible: false,
				fenpeiVisible: false,
				addRoleForm: {
					role_id: '',
					role_name: '',
					role_desc: ''
				},
				addRoleRules: {
					role_name: [{
						required: true,
						message: '请输入角色名称',
						trigger: 'blur'
					}],
				},
				menuList: [],
				treeProps: {
					children: 'children',
					label: 'menu_name'
				},
				defKeys: []
			}
		},
		created() {
			that = this;
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				this.getMenuList();
				this.getRoleList();
			}
		},
		methods: {
			getMenuList() {
				var url = 'menu/menu_list';
				let params = {
					role_type: 1
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.menuList = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			getRoleList() {
				var url = 'admin/role_list';
				let params = {
					role_name: this.role_name,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.roleList = res.list;
						console.log(res.list);
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//查询
			search() {
				this.getRoleList();
			},
			// 重置
			reset() {
				this.role_name = "";
				this.getRoleList();
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1
				this.queryInfo.pagesize = newSize
				this.getRoleList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage
				this.getRoleList();
			},
			//单选
			select(selection, row) {
				this.chooseList = selection
			},
			//全选
			selectAll(selection) {
				this.chooseList = selection
			},
			//添加弹窗打开
			addRole() {
				this.title = '添加角色';
				this.addRoleForm = {
					role_id: '',
					role_name: '',
					role_desc: ''
				};
				this.addRoleVisible = true
			},
			updateRole(item) {
				this.title = '编辑角色';
				this.addRoleForm = {
					role_id: item.role_id,
					role_name: item.role_name,
					role_desc: item.role_desc
				};
				this.addRoleVisible = true
			},
			//关闭添加弹窗
			addClose() {
				this.$refs.addRoleRef.resetFields()
				this.addRoleForm.remarks = ''
				this.addRoleVisible = false
			},
			//确认添加
			addSub() {
				this.$refs.addRoleRef.validate(value => {
					if(!value) return
					if(timer != null) {
						clearTimeout(timer);
					}
					timer = setTimeout(() => {
						var url = 'admin/addOrUpdateRole';
						let params = that.addRoleForm;
						that.fd_post(url, params).then((res) => {
							if(res.status) {
								that.addRoleVisible = false;
								that.$refs.addRoleRef.resetFields();
								that.$message.success('操作成功');
								that.getRoleList();
							} else {
								that.$message.error(res.msg);
							}
						}).catch((err) => {
							that.$message.error('网络错误');
						});
					}, 300);
				})
			},
			//分配弹窗打开
			fenpei(role) {
				this.defKeys = [];
				if(this.$refs.treeRef!=undefined){
					this.$refs.treeRef.setCheckedKeys([]);
				}
				this.role_id = role.role_id;
				role.auth_list.forEach(item=>{
					this.getdefKeys(item, this.defKeys);
				});
  				this.fenpeiVisible = true;
			},
			//获取拥有的权限列表
			getdefKeys(role, arr) {
				if(role.children.length==0){
					return arr.push(role.menu_id);
				}
				role.children.forEach(item=>{
					this.getdefKeys(item, arr);
				})
			},
			//分配弹窗关闭
			fenpeiClose() {
				this.fenpeiVisible = false
			},
			//确定分配
			fenpeiSub() {
				const keys = [
					...this.$refs.treeRef.getCheckedKeys(),
					...this.$refs.treeRef.getHalfCheckedKeys()
				]
				if(keys.length>0){
					if(timer != null) {
						clearTimeout(timer);
					}
					timer = setTimeout(() => {
						var url = 'admin/setRoleAuth';
						let params = {
							role_id: this.role_id,
							role_auth: keys
						};
						that.fd_post(url, params).then((res) => {
							this.fenpeiVisible = false;
							if(res.status) {
								that.$message.success("操作成功");
								that.getRoleList();
							} else {
								that.$message.error(res.msg);
							}
						}).catch((err) => {
							this.fenpeiVisible = false;
							that.$message.error('网络错误');
						});
					}, 300);
				}
			},
			//设置技师上岗状态
			setRoleStatus(item) {
				if(timer != null) {
					clearTimeout(timer);
				}
				timer = setTimeout(() => {
					var url = 'admin/setRoleStatus';
					let params = {
						role_id: item.role_id
					};
					that.fd_post(url, params).then((res) => {
						if(res.status) {
							that.$message.success("操作成功");
						} else {
							that.$message.error(res.msg);
						}
					}).catch((err) => {
						that.$message.error('网络错误');
					});
				}, 300);
			},
			//多选删除
			removeAll() {
				if(this.chooseList.length == 0) {
					this.$message.info('请至少选择一条');
					return;
				}
				this.$confirm('此操作将永久删除选中的数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if(timer != null) {
						clearTimeout(timer);
					}
					timer = setTimeout(() => {
						var url = 'admin/deleteRole';
						let params = {
							role_type: 1,
							role_id: JSON.stringify(that.chooseList)
						};
						that.fd_post(url, params).then((res) => {
							if(res.status) {
								that.$message.success("操作成功");
								that.getRoleList();
							} else {
								that.$message.error(res.msg);
							}
						}).catch((err) => {
							that.$message.error('网络错误');
						});
					}, 300);
				}).catch(() => {
					this.$message.info('已取消删除');
				});
			},
			//删除
			remove(item) {
				this.$confirm('你确定要删除吗,此操作将永久删除该条数据?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if(timer != null) {
						clearTimeout(timer);
					}
					timer = setTimeout(() => {
						var url = 'admin/deleteRole';
						let params = {
							role_id: item.role_id
						};
						that.fd_post(url, params).then((res) => {
							if(res.status) {
								that.$message.success("操作成功");
								that.getRoleList();
							} else {
								that.$message.error(res.msg);
							}
						}).catch((err) => {
							that.$message.error('网络错误');
						});
					}, 300);
				}).catch(() => {
					this.$message.info('已取消删除');
				});
			},
		},
	}
</script>

<style lang="scss" scoped>
	@import './Role_manage.scss';
</style>